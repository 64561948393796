import React, { StrictMode } from 'react';
import { render } from 'react-dom';

export function renderComponent(component, rootElementClass, props = window.props) {
  const Component = component;

  render(
    <StrictMode>
      <Component {...props} />
    </StrictMode>,
    document.querySelector(`.${rootElementClass}`)
  );
}
