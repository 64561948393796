import { enc, lib, MD5, SHA256 } from 'crypto-js';

export class FileUploader {
  constructor(awsAccessKey, awsBucketName, S3SignatureApiUrl) {
    this.awsAccessKey = awsAccessKey;
    this.awsBucketName = awsBucketName;
    this.maxRetries = 10;
    this.S3SignatureApiUrl = S3SignatureApiUrl;

    this.config = this.config.bind(this);
    this.upload = this.upload.bind(this);
  }

  async config() {
    const awsRegion = 'eu-west-1';

    try {
      this.evaporateInstance = await window.Evaporate.create({
        /* eslint-disable camelcase */
        aws_key: this.awsAccessKey,
        aws_url: `https://s3-${awsRegion}.amazonaws.com`,
        /* eslint-enable camelcase */
        awsRegion,
        awsSignatureVersion: '4',
        bucket: this.awsBucketName,
        computeContentMd5: true,
        cryptoHexEncodedHash256(data) {
          // eslint-disable-next-line new-cap
          return SHA256(data).toString(enc.Hex);
        },
        cryptoMd5Method(data) {
          // eslint-disable-next-line new-cap
          return MD5(lib.WordArray.create(data)).toString(enc.Base64);
        },
        encodeFilename: false,
        logging: false,
        progressIntervalMS: 500,
        signerUrl: `${this.S3SignatureApiUrl}attachments/`
      });

      return this.evaporateInstance;
    } catch (error) {
      throw error;
    }
  }

  async upload(file, uploadPath, progress) {
    let retries = 1;

    try {
      const evaporateInstance = this.evaporateInstance || (await this.config());

      return await evaporateInstance.add({
        contentType: file.type,
        file,
        name: uploadPath,
        progress,
        warn: () => {
          if (retries <= this.maxRetries) {
            retries = retries + 1;
          } else {
            evaporateInstance.cancel(`${this.awsBucketName}/${uploadPath}`);
          }
        },
        xAmzHeadersAtInitiate: {
          'content-disposition': 'attachment',
          'x-amz-acl': 'public-read'
        }
      });
    } catch (error) {
      throw error;
    }
  }
}
